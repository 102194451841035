import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/InfoCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/layout/AboutUs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/layout/HeroHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/layout/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
